import { ComponentRef } from '@wix/yoshi-flow-editor';
import { ElementData } from '@wix/editor-platform-sdk-types';

import { ElementId, ViewStateId } from '../constants';
import { ElementId as ContentElementId } from '../../referralPageContent/constants';
import { EditorScriptContext, ElementsPanelData } from '../../../editor';

export const getElementsPanelData = async (
  widgetRef: ComponentRef,
  { flowAPI, editorSDK }: EditorScriptContext,
): Promise<ElementsPanelData> => {
  const { t } = flowAPI.translations;

  const viewStateId = await editorSDK.editor.widgets.getViewState('', {
    componentRef: widgetRef,
  });

  const elementsData: Pick<ElementData, 'label' | 'identifier'>[] = [
    {
      label: t('referral-page.element.title'),
      identifier: { role: ContentElementId.TitleText },
    },
    {
      label: t('referral-page.element.subtitle'),
      identifier: { role: ContentElementId.SubtitleText },
    },
  ];

  if (viewStateId === ViewStateId.LoggedIn) {
    elementsData.push(
      ...[
        {
          label: t('referral-page.element.divider'),
          identifier: { role: ContentElementId.Divider },
        },
        {
          label: t('referral-page.element.apply-code-text'),
          identifier: { role: ContentElementId.ApplyCodeText },
        },
        {
          label: t('referral-page.element.my-rewards-text'),
          identifier: { role: ContentElementId.MyRewardsText },
        },
      ],
    );
  }

  elementsData.push(
    ...[
      {
        label: t('referral-page.element.explanations'),
        identifier: { role: ContentElementId.ExplanationsText },
      },
      {
        label: t('referral-page.element.image'),
        identifier: { role: ElementId.Image },
      },
    ],
  );

  const data: ElementsPanelData = {
    categoriesData: [],
    elementsData: elementsData.map<ElementData>((element, index) => ({
      ...element,
      index,
    })),
  };

  return data;
};
