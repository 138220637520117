import { ComponentRef } from '@wix/yoshi-flow-editor';

import { getWidgetId } from './getWidgetId';
import { EditorScriptContext } from '../types';

import referFriendPageComponent from '../../components/referFriendPage/.component.json';
import referFriendPageContentComponent from '../../components/referFriendPageContent/.component.json';
import referralPageComponent from '../../components/referralPage/.component.json';
import referralPageContentComponent from '../../components/referralPageContent/.component.json';
import notificationBannerComponent from '../../components/notificationBanner/.component.json';

const widgetIdToComponentNameMap: Record<string, string> = {
  [referFriendPageComponent.id]: 'referFriendPage',
  [referFriendPageContentComponent.id]: 'referFriendPageContent',
  [referralPageComponent.id]: 'referralPage',
  [referralPageContentComponent.id]: 'referralPageContent',
  [notificationBannerComponent.id]: 'notificationBanner',
};

export const getWidgetComponentName = async (
  widgetRef: ComponentRef,
  context: EditorScriptContext,
): Promise<string | undefined> => {
  const widgetId = await getWidgetId(widgetRef, context);
  return widgetIdToComponentNameMap[widgetId];
};
