export enum ElementId {
  AvailableState = 'availableState',
  ContentContainer = 'contentContainer',
  Content = 'content',
  CookieManager = 'cookieManager',
  Image = 'image',
  ImageContainer = 'imageContainer',
  NotAvailableState = 'notAvailableState',
  NotificationBanner = 'notificationBanner',
  NotificationBannerRoot = 'notificationBannerRoot',
  ProgramStates = 'programStates',
  SpacingContainer = 'spacingContainer',
  StatusText = 'statusText',
}

export enum FedOpsInteraction {
  LoadReferralCode = 'load-referral-code',
}

export enum ViewStateId {
  LoggedIn = 'loggedIn',
  LoggedOut = 'loggedOut',
  Notification = 'notification',
}
