import { MA_APP_IDS } from '@wix/members-area-integration-kit';

export enum Event {
  DeleteApp = 'deleteReferralsApp',
  Manage = 'manageReferrals',
}

export enum GfppAction {
  ManageProgram = 'manageProgram',
  OpenElementsPanel = 'openElementsPanel',
  OpenLayoutPanel = 'openLayoutPanel',
  OpenSwitchStatePanel = 'openSwitchStatePanel',
}

export const REQUIRED_MEMBERS_AREA_APPS = [MA_APP_IDS.MY_REWARDS];
