import { ComponentRef } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { EditorScriptContext } from '../types';
import { getWidgetComponentName } from '../utils';
import { HelpId } from '../helpId';

const panelHeightByComponent: Record<string, number> = {
  referFriendPage: 204,
  referralPage: 185,
};

export const openSwitchStatePanel = async (widgetRef: ComponentRef, context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;
  const { t } = flowAPI.translations;
  const componentName = await getWidgetComponentName(widgetRef, context);

  if (!componentName) {
    return;
  }

  await editorSDK.editor.openComponentPanel('', {
    title: t('switch-state-panel.title'),
    url: getPanelUrl(componentName, 'SwitchState'),
    helpId: HelpId.SwitchState,
    width: 288,
    height: panelHeightByComponent[componentName],
    componentRef: widgetRef,
    initialData: { widgetRef },
  });
};
