import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId } from './constants';
import { configureButtonComponent } from '../../editor';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    nickname: 'copyText',
  });

  widgetBuilder.configureConnectedComponents(ElementId.TextInput, (builder) => {
    builder
      .gfpp()
      .set('connect', { behavior: 'HIDE' })
      .set('settings', { behavior: 'HIDE' })
      // Workaround as we cannot hide "Settings" main action for some reason.
      .set('mainAction1', { label: t('gfpp.change-design'), actionType: 'design', actionId: '' })
      .set('mainAction2', { behavior: 'HIDE' });

    builder.behavior().set({ preventHide: true });
    builder
      .panel<'TextInput'>('design')
      .configureStates({
        error: {
          hidden: true,
        },
      })
      .configureControls({
        // Disable top "Text" section
        fntprefix: {
          hidden: true,
        },
        // Disable "Field title" section
        fntlbl: {
          hidden: true,
        },
        txtlbl: {
          hidden: true,
        },
        txtlblrq: {
          hidden: true,
        },
      } as any);
  });

  widgetBuilder.configureConnectedComponents(
    ElementId.CopyButton,
    configureButtonComponent({
      flowAPI,
      allowIconEdit: false,
    }),
  );
};
