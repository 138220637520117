import { ComponentRef } from '@wix/yoshi-flow-editor';
import { ElementData, ElementCategoryData } from '@wix/editor-platform-sdk-types';

import { ElementId, ViewStateId } from '../constants';
import { ElementId as ContentElementId } from '../../referFriendPageContent/constants';
import { ElementId as NotificationBannerElementId } from '../../notificationBanner/constants';
import { EditorScriptContext, ElementsPanelData } from '../../../editor';

enum CategoryId {
  Notification = 'notification',
  ProgramInfo = 'programInfo',
  CopyAndShare = 'copyAndShare',
}

export const getElementsPanelData = async (
  widgetRef: ComponentRef,
  { flowAPI, editorSDK }: EditorScriptContext,
): Promise<ElementsPanelData> => {
  const { t } = flowAPI.translations;
  const categoriesData: ElementCategoryData[] = [];
  const elementsData: ElementData[] = [];

  const viewStateId = await editorSDK.editor.widgets.getViewState('', {
    componentRef: widgetRef,
  });

  if (viewStateId === ViewStateId.Notification) {
    categoriesData.push({
      id: CategoryId.Notification,
      title: t('refer-friend-page.element-category.notification'),
    });

    elementsData.push(
      ...[
        {
          label: t('refer-friend-page.element.notificationTitle'),
          identifier: { role: NotificationBannerElementId.NotificationTitleText },
        },
        {
          label: t('refer-friend-page.element.notificationDescription'),
          identifier: { role: NotificationBannerElementId.NotificationDescriptionText },
        },
      ].map<ElementData>((element, index) => ({
        ...element,
        categoryId: CategoryId.Notification,
        index,
      })),
    );
  }

  categoriesData.push({
    id: CategoryId.ProgramInfo,
    title: t('refer-friend-page.element-category.program-info'),
  });

  const programInfoElements: Pick<ElementData, 'label' | 'identifier'>[] = [
    {
      label: t('refer-friend-page.element.title'),
      identifier: { role: ContentElementId.TitleText },
    },
    {
      label: t('refer-friend-page.element.subtitle'),
      identifier: { role: ContentElementId.SubtitleText },
    },
    {
      label: t('refer-friend-page.element.description'),
      identifier: { role: ContentElementId.DescriptionText },
    },
  ];

  if (viewStateId === ViewStateId.LoggedIn) {
    programInfoElements.push({
      label: t('refer-friend-page.element.divider'),
      identifier: { role: ContentElementId.Divider },
    });
  }

  programInfoElements.push(
    ...[
      {
        label: t('refer-friend-page.element.explanations'),
        identifier: { role: ContentElementId.ExplanationsText },
      },
      {
        label: t('refer-friend-page.element.image'),
        identifier: { role: ElementId.Image },
      },
    ],
  );

  elementsData.push(
    ...programInfoElements.map<ElementData>((element, index) => ({
      ...element,
      categoryId: CategoryId.ProgramInfo,
      index,
    })),
  );

  if (viewStateId === ViewStateId.LoggedIn) {
    categoriesData.push({
      id: CategoryId.CopyAndShare,
      title: t('refer-friend-page.element-category.copy-and-share'),
    });

    elementsData.push(
      ...[
        {
          label: t('refer-friend-page.element.copy-link-text'),
          identifier: { role: ContentElementId.CopyLinkText },
        },
        {
          label: t('refer-friend-page.element.share-text'),
          identifier: { role: ContentElementId.ShareText },
        },
        {
          label: t('refer-friend-page.element.social-bar'),
          identifier: { role: ContentElementId.SocialBar },
        },
      ].map<ElementData>((element, index) => ({
        ...element,
        categoryId: CategoryId.CopyAndShare,
        index,
      })),
    );
  }

  return {
    categoriesData,
    elementsData,
  };
};
