import { ComponentRef } from '@wix/yoshi-flow-editor';
import { PageRef, WidgetInstallationType } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext, PageConfig } from '../types';

interface BlocksWidgetInClassicEditorParams {
  pageRef: PageRef;
  pageConfig: PageConfig;
}

export const addBlocksWidgetInClassicEditor = async (
  { editorSDK }: EditorScriptContext,
  { pageRef, pageConfig }: BlocksWidgetInClassicEditorParams,
): Promise<ComponentRef> => {
  const { widgetId, presets, classicEditorSize, verticalMargin } = pageConfig;

  const pageChildren = await editorSDK.components.getChildren('', {
    componentRef: pageRef as ComponentRef,
  });

  // Target existing "Page Section" container used in Classic Editor
  const containerRef: ComponentRef = pageChildren[0] || pageRef;

  return editorSDK.application.appStudioWidgets.addWidget('', {
    widgetId,
    installationType: WidgetInstallationType.Closed,
    containerRef,
    layout: {
      ...(classicEditorSize.stretched && {
        docked: {
          left: {
            px: 0,
            vw: 0,
          },
          right: {
            px: 0,
            vw: 0,
          },
        },
        x: 0,
        y: verticalMargin ?? 0,
      }),
      fixedPosition: false,
      width: classicEditorSize.width,
      height: classicEditorSize.height,
      scale: 1,
      rotationInDegrees: 0,
    } as any,
    scopedPresets: {
      desktop: {
        layout: presets.desktop,
        style: presets.desktop,
      },
      mobile: {
        layout: presets.mobile,
        style: presets.mobile,
      },
    },
  });
};
