import { ComponentRef } from '@wix/yoshi-flow-editor';
import { ElementData, ElementCategoryData } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext } from '../types';
import { referFriendPage, referralPage } from '../pages';
import { getWidgetId } from '../utils';
import { getElementsPanelData as getReferFriendPageElementsPanelData } from '../../components/referFriendPage/editor';
import { getElementsPanelData as getReferralPageElementsPanelData } from '../../components/referralPage/editor';

export interface ElementsPanelData {
  categoriesData: ElementCategoryData[];
  elementsData: ElementData[];
}

export const getElementsPanelData = async (
  widgetRef: ComponentRef,
  context: EditorScriptContext,
): Promise<ElementsPanelData> => {
  const widgetId = await getWidgetId(widgetRef, context);

  if (widgetId === referFriendPage.widgetId) {
    return getReferFriendPageElementsPanelData(widgetRef, context);
  } else if (widgetId === referralPage.widgetId) {
    return getReferralPageElementsPanelData(widgetRef, context);
  } else {
    return {
      categoriesData: [],
      elementsData: [],
    };
  }
};
