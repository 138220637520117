import { WidgetDesignPreset, PlatformUnitSize } from '@wix/editor-platform-sdk-types';

import desktopImageSrc from './presets/desktop.png';
import sideToSideImageSrc from './presets/sideToSide.png';
import overlappingImageSrc from './presets/overlapping.png';
import verticalImageSrc from './presets/vertical.png';
import containerImageSrc from './presets/container.png';

interface PresetDesignVariables {
  horizontalSpacing: PlatformUnitSize;
  topSpacing: PlatformUnitSize;
  bottomSpacing: PlatformUnitSize;
}

const PRESET_ID_FULL_WIDTH = 'variants-lcep6zn51';
const PRESET_ID_SIDE_TO_SIDE = 'variants-lfjtaovy';
const PRESET_ID_OVERLAPPING = 'variants-lfm9o9pc';
const PRESET_ID_VERTICAL = 'variants-lfjmpl7d';
const PRESET_ID_CONTAINER_ONLY = 'variants-lfclvom8';

// NOTE: This is defined in Blocks and should ideally be pulled from some API - but there is no API
// right now to get default values for Blocks design variables. So we maintain a map in code.
export const designVariablesByPreset: Record<string, PresetDesignVariables> = {
  [PRESET_ID_FULL_WIDTH]: {
    horizontalSpacing: {
      value: 0,
      unit: 'percentage',
    },
    topSpacing: {
      value: 0,
      unit: 'px',
    },
    bottomSpacing: {
      value: 0,
      unit: 'px',
    },
  },
  [PRESET_ID_SIDE_TO_SIDE]: {
    horizontalSpacing: {
      value: 17,
      unit: 'percentage',
    },
    topSpacing: {
      value: 48,
      unit: 'px',
    },
    bottomSpacing: {
      value: 48,
      unit: 'px',
    },
  },
  [PRESET_ID_OVERLAPPING]: {
    horizontalSpacing: {
      value: 17,
      unit: 'percentage',
    },
    topSpacing: {
      value: 48,
      unit: 'px',
    },
    bottomSpacing: {
      value: 48,
      unit: 'px',
    },
  },
  [PRESET_ID_VERTICAL]: {
    horizontalSpacing: {
      value: 22,
      unit: 'percentage',
    },
    topSpacing: {
      value: 48,
      unit: 'px',
    },
    bottomSpacing: {
      value: 48,
      unit: 'px',
    },
  },
  [PRESET_ID_CONTAINER_ONLY]: {
    horizontalSpacing: {
      value: 25,
      unit: 'percentage',
    },
    topSpacing: {
      value: 48,
      unit: 'px',
    },
    bottomSpacing: {
      value: 48,
      unit: 'px',
    },
  },
};

export const presets: WidgetDesignPreset[] = [
  // Desktop (Full-width layout)
  {
    id: PRESET_ID_FULL_WIDTH,
    src: desktopImageSrc,
    layout: {
      width: '240px',
      height: '100px',
    },
  },
  // Overlapping
  {
    id: PRESET_ID_OVERLAPPING,
    src: overlappingImageSrc,
    layout: {
      width: '240px',
      height: '112px',
    },
  },
  // Side to side
  {
    id: PRESET_ID_SIDE_TO_SIDE,
    src: sideToSideImageSrc,
    layout: {
      width: '240px',
      height: '90px',
    },
  },
  // Vertical
  {
    id: PRESET_ID_VERTICAL,
    src: verticalImageSrc,
    layout: {
      width: '240px',
      height: '165px',
    },
  },
  // Container only
  {
    id: PRESET_ID_CONTAINER_ONLY,
    src: containerImageSrc,
    layout: {
      width: '240px',
      height: '123px',
    },
  },
].map((preset) => ({
  ...preset,
  id: `externalPreset-${preset.id}`, // For custom preset change logic
}));
