import { PageRef } from '@wix/editor-platform-sdk-types';

import { EditorScriptContext, PageConfig } from '../types';
import { createResponsiveDefinitions, defaultBreakpoints } from './responsiveDefinitions';
import { addBlocksWidgetInClassicEditor } from './addBlocksWidgetInClassicEditor';

export const createPage = async (context: EditorScriptContext, pageConfig: PageConfig): Promise<PageRef> => {
  const { editorSDK, flowAPI, applicationId } = context;
  const { t } = flowAPI.translations;
  const { isEditorX, appDefinitionId } = flowAPI.environment;

  const appPages = await editorSDK.pages.getApplicationPages('');
  const existingPage = appPages.find(({ tpaPageId }) => tpaPageId === pageConfig.id);

  // NOTE: It's possible to trigger app install multiple times from EditorX app market. Avoid adding
  // the page if it already exists.
  if (existingPage) {
    return editorSDK.components.getById('', { id: existingPage.id! });
  }

  const components = isEditorX ? [createResponsiveDefinitions(context, pageConfig)] : [];

  const pageRef = await editorSDK.pages.add('', {
    title: t(pageConfig.titleKey),
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      components: components as any,
      breakpoints: isEditorX ? defaultBreakpoints : undefined,
      data: {
        managingAppDefId: appDefinitionId,
        tpaApplicationId: applicationId,
        tpaPageId: pageConfig.id,
        pageUriSEO: pageConfig.uri,
      },
    },
    shouldAddMenuItem: pageConfig.addToMenu,
    shouldNavigateToPage: false,
  });

  if (!isEditorX) {
    await addBlocksWidgetInClassicEditor(context, {
      pageRef,
      pageConfig,
    });
  }

  return pageRef;
};
