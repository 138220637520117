import { PagesTabBuilder } from '@wix/yoshi-flow-editor';

import { EditorScriptContext } from '../types';
import { Event } from '../constants';
import { HelpId } from '../helpId';

export const configurePagesTab = ({ flowAPI }: EditorScriptContext, pagesTabBuilder: PagesTabBuilder) => {
  const { t } = flowAPI.translations;

  pagesTabBuilder
    .set({
      displayName: t('pages.tab'),
      helpId: HelpId.ReferralPages,
    })
    .setGrouping('none')
    .addAction({
      title: t('pages.action.manage'),
      icon: 'settingsAction',
      event: Event.Manage,
    })
    .addAction({
      title: t('pages.action.delete-app'),
      icon: 'deleteAction',
      event: Event.DeleteApp,
    });
};
