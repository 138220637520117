import {
  installMembersArea as installMembersAreaMainApp,
  addApplications as installMembersAreaApps,
} from '@wix/members-area-integration-kit';

import { REQUIRED_MEMBERS_AREA_APPS } from '../constants';

export async function installMembersArea(): Promise<void> {
  await installMembersAreaMainApp();
  await installMembersAreaApps(REQUIRED_MEMBERS_AREA_APPS);
}
