import { ComponentRef } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';

import { EditorScriptContext } from '../types';
import { getWidgetComponentName } from '../utils';
import { HelpId } from '../helpId';

const panelHeightByComponent: Record<string, number> = {
  referFriendPage: 250,
  referralPageContent: 145,
  referFriendPageContent: 145,
  notificationBanner: 145,
};

export const openLayoutPanel = async (widgetRef: ComponentRef, context: EditorScriptContext) => {
  const { editorSDK, flowAPI } = context;
  const { t } = flowAPI.translations;
  const componentName = await getWidgetComponentName(widgetRef, context);

  if (!componentName) {
    return;
  }

  await editorSDK.editor.openComponentPanel('', {
    title: t('layout-panel.title'),
    url: getPanelUrl(componentName, 'Layout'),
    type: editorSDK.editor.PanelType.Layout,
    helpId: HelpId.Layout,
    width: 288,
    height: panelHeightByComponent[componentName],
    componentRef: widgetRef,
    initialData: { widgetRef },
  });
};
