import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { withMembersArea } from '@wix/members-area-integration-kit';
import { Exports } from '@wix/editor-platform-sdk-types';

import {
  REQUIRED_MEMBERS_AREA_APPS,
  EditorScriptContext,
  installApp,
  setStatesForPages,
  handleEvents,
  configurePages,
  configurePagesTab,
} from './editor';

const _editorReady: EditorReadyFn = async (editorSDK, appDefinitionId, { firstInstall, initialAppData }, flowAPI) => {
  const context: EditorScriptContext = {
    editorSDK,
    flowAPI,
    applicationId: initialAppData.applicationId,
  };

  if (firstInstall) {
    await installApp(context);
  }

  await setStatesForPages(context);
  await handleEvents(context);
};

const _getAppManifest: GetAppManifestFn = async (options, editorSDK, { initialAppData }, flowAPI) => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;
  const context: EditorScriptContext = {
    editorSDK,
    flowAPI,
    applicationId: initialAppData.applicationId,
  };

  const baseManifest = await fetchWidgetsStageData(options as any, t);

  return appManifestBuilder
    .withJsonManifest(baseManifest as any)
    .configurePagesTab((pagesTabBuilder) => configurePagesTab(context, pagesTabBuilder))
    .configurePages((pagesBuilder) => configurePages(context, pagesBuilder))
    .build();
};

export const { editorReady, getAppManifest, handleAction, onEvent } = withMembersArea(
  {
    editorReady: _editorReady,
    getAppManifest: _getAppManifest,
    handleAction: async () => {},
  },
  {
    installAutomatically: false,
    installAppsAutomatically: false,
    membersAreaApps: REQUIRED_MEMBERS_AREA_APPS,
  },
);

export const exports: Exports = (editorSDK, { biData }) => ({
  editor: {
    async getSelectedWidgetDesignPresetId({ widgetRef }) {
      const selectedPreset = await editorSDK.application.appStudioWidgets.getPreset('', {
        componentRef: widgetRef,
      });

      return `externalPreset-${selectedPreset.style}`;
    },
    // NOTE: With Blocks apps the `handleAction` is no longer called by editor platform and `withMembersArea`
    // depend on this callback to install our "My Rewards" members area page. As a workaround, we are forwarding
    // fake `handleAction` events here with `appInstalled/removeApp` below.
    // See for more details: https://wix.slack.com/archives/C64RX2LA3/p1680072471990359
    async appInstalled({ appDefinitionId }) {
      return handleAction?.(
        {
          type: 'appInstalled',
          payload: {
            appDefinitionId,
            biData,
          },
        },
        editorSDK as any,
      );
    },
    async removeApp() {
      return handleAction?.(
        {
          type: 'removeApp',
          payload: {},
        },
        editorSDK as any,
      );
    },
  },
});
