import { ComponentRef } from '@wix/yoshi-flow-editor';
import { EditorScriptContext } from '../types';

interface WidgetComponentData {
  widgetId: string;
}

export const getWidgetId = async (widgetRef: ComponentRef, { editorSDK }: EditorScriptContext): Promise<string> => {
  // Get parent host component (wysiwyg.viewer.components.RefComponent)
  const [widgetHostRef] = await editorSDK.components.getAncestors('', {
    componentRef: widgetRef,
  });

  const compData = (await editorSDK.components.data.get('', {
    componentRef: widgetHostRef,
  })) as WidgetComponentData;

  return compData.widgetId;
};
