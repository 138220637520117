import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId, ViewStateId } from './constants';
import { ElementId as ContentElementId } from '../referralPageContent/constants';
import { ElementId as CopyTextElementId } from '../copyText/constants';
import {
  GfppAction,
  HelpId,
  configurePageWidget,
  configureImageComponent,
  configureComponentAsHidden,
  configureBoxComponent,
  configureGlobalDesignTabs,
} from '../../editor';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  configurePageWidget(widgetBuilder, {
    flowAPI,
    displayName: t('pages.title.referral'),
    nickname: 'referralPageWidget',
    helpId: HelpId.ReferralLandingPage,
  });

  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t('referral-page.design.title'),
    });
  });

  widgetBuilder.configureWidgetDesign(
    configureGlobalDesignTabs([
      {
        label: t('referral-page.design.tab.title'),
        roles: [ContentElementId.TitleText],
      },
      {
        label: t('referral-page.design.tab.body-text'),
        roles: [ContentElementId.SubtitleText, ContentElementId.ApplyCodeText, ContentElementId.MyRewardsText],
      },
      {
        label: t('referral-page.design.tab.secondary-text'),
        roles: [ContentElementId.ExplanationsText],
      },
      {
        label: t('referral-page.design.tab.button'),
        roles: [ContentElementId.GetRewardButton, CopyTextElementId.CopyButton],
      },
      {
        label: t('referral-page.design.tab.box-background'),
        roles: [ElementId.ContentContainer],
      },
      {
        label: t('referral-page.design.tab.image-background'),
        roles: [ElementId.ImageContainer],
      },
    ]),
  );

  widgetBuilder.configureWidgetViewState(ViewStateId.LoggedIn, (viewStateBuilder) => {
    viewStateBuilder.title(t('referral-page.state.logged-in'));
  });

  widgetBuilder.configureWidgetViewState(ViewStateId.LoggedOut, (viewStateBuilder) => {
    viewStateBuilder.title(t('referral-page.state.logged-out'));
  });

  widgetBuilder.configureConnectedComponents(ElementId.CookieManager, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.ProgramStates, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.AvailableState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.NotAvailableState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.StatusText, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.Content, configureComponentAsHidden());

  widgetBuilder.configureConnectedComponents(ElementId.ContentContainer, (builder) => {
    configureBoxComponent({
      displayName: t('referral-page.element.content'),
    })(builder);

    builder.gfpp().set('layout', { actionId: GfppAction.OpenLayoutPanel });
  });

  widgetBuilder.configureConnectedComponents(
    ElementId.ImageContainer,
    configureBoxComponent({
      displayName: t('referral-page.element.image-container'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.Image,
    configureImageComponent({
      displayName: t('referral-page.element.image'),
    }),
  );
};
