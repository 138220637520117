import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';

import { ElementId } from './constants';
import {
  configureTextComponent,
  configureButtonComponent,
  configureComponentAsHidden,
  configureLineComponent,
} from '../../editor';

export const getWidgetManifest: GetWidgetManifestFn = (widgetBuilder, editorSDK, flowAPI) => {
  const { t } = flowAPI.translations;

  widgetBuilder.set({
    nickname: 'referralPageContent',
  });

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    pinnable: false,
    preventHide: true,
  });

  widgetBuilder.gfpp().set('connect', { behavior: 'HIDE' }).set('add', { behavior: 'HIDE' });

  widgetBuilder.configureConnectedComponents(ElementId.RewardStates, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.LoadingState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.CouponRewardState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.GetRewardState, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.Loader, configureComponentAsHidden());
  widgetBuilder.configureConnectedComponents(ElementId.CopyCode, configureComponentAsHidden());

  widgetBuilder.configureConnectedComponents(
    ElementId.TitleText,
    configureTextComponent({
      displayName: t('referral-page.element.title'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.SubtitleText,
    configureTextComponent({
      displayName: t('referral-page.element.subtitle'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.ExplanationsText,
    configureTextComponent({
      displayName: t('referral-page.element.explanations'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.GetRewardButton,
    configureButtonComponent({
      flowAPI,
      allowLabelEdit: false,
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.Divider,
    configureLineComponent({
      displayName: t('referral-page.element.divider'),
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.ApplyCodeText,
    configureTextComponent({
      displayName: t('referral-page.element.apply-code-text'),
      allowTextEdit: true,
    }),
  );

  widgetBuilder.configureConnectedComponents(
    ElementId.MyRewardsText,
    configureTextComponent({
      displayName: t('referral-page.element.my-rewards-text'),
    }),
  );
};
